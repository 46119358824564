<template>
  <div class="areaBition">
    <div class="content" v-html="content">

    </div>
  </div>
</template>

<script>
import {
  getCloudNoticeListData,
} from "@/api/index";
export default {
  data(){
    return {
        content:''
    }
  },
  created(){
    this.initTbel();
  },
  methods:{
    initTbel(){
        let datas = {
            key:'cpp'
        }
        getCloudNoticeListData(datas).then(res=>{
            this.content = res.data.value;
        })
    }
  }
}
</script>

<style scoped lang="less">
.areaBition{
  background: #fff;
}
.content{
  margin: 0 auto;
  text-align: left;
  padding:10px;
  ::v-deep{
    p{
      margin: 0 0 16px 0;
      &:last-child{
        padding:0 10px!important;
        line-height: 25px;
      }
    }
  }
}
</style>
